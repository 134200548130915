import { encode, decode } from 'html-entities';

export class HtmlUtils {
  static encodeHtml(html: string): string {
    return encode(html);
  }

  static decodeHtml(html: string): string {
    return decode(html);
  }
}
