import { LocalisationConstants } from '@constants/localisation-constants';

export enum ErrorPageType {
  NoEntitlement = 'no-entitlement',
  NoData = 'getting-started',
  AppUnavailable = 'app-unavailable',
  AppLoadingError = 'app-loading-error',
}

export interface ErrorPageDetails {
  svgPath: string;
  message: string;
  helpLinkSegment?: string;
  helpLinkText?: string;
}

export const errorPageSettings: { [key: string]: ErrorPageDetails } = {
  [ErrorPageType.AppUnavailable]: {
    svgPath: '/assets/svg/SystemError.svg',
    message: LocalisationConstants.APP_UNAVAILABLE.DEFAULT_MESSAGE,
  },
  [ErrorPageType.AppLoadingError]: {
    svgPath: '/assets/svg/SystemError.svg',
    message: LocalisationConstants.APP_LOADING_ERROR.DEFAULT_MESSAGE,
  },
  [ErrorPageType.NoData]: {
    svgPath: '/assets/hig/empty-state/empty-state-first-use-light-gray.svg',
    message: LocalisationConstants.GETTING_STARTED.DEFAULT_MESSAGE,
    helpLinkSegment: 'MEPCE_Help_GetStarted_html',
    helpLinkText: LocalisationConstants.GETTING_STARTED.LEARN_HOW,
  },
  [ErrorPageType.NoEntitlement]: {
    svgPath: '/assets/svg/NoPermission.svg',
    message: LocalisationConstants.NO_ENTITLEMENT.DEFAULT_MESSAGE,
  },
};
