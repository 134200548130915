import { ErrorPageType } from './error-page-constants';

export class NavigationConstants {
  // data root
  static readonly NAV_DATA_ROOT = '/data';

  static readonly AUTODESK_LINK = 'https://www.autodesk.com/';

  static readonly NAV_CONFIG_ROOT = '/data/config';
  // data root
  static readonly NAV_AUTH_ROOT = '/auth';
  // graph end-point
  static readonly NAV_GRAPH_ENDPOINT = '/graph';
  // query params
  static readonly QUERY_STRING_TEMP_MODEL_ID = 'tempModelId';
  static readonly QUERY_STRING_PALETTE_ID = 'palette';
  static readonly QUERY_STRING_SIZE_RESTRICTION_ID = 'sizeRestriction';
  static readonly QUERY_STRING_PART_COLLECTION_ID = 'partCollection';

  // config switcher
  static readonly NAV_DATA_SELECTOR_TITLE = 'Fabrication Configurations';

  // headers
  static readonly NAV_SUPER_HEADER_HAS_DATA = 'Fabrication Configuration';
  static readonly NAV_SUPER_HEADER_NO_DATA = 'No Data';
  static readonly NAV_HEADER_LINK = '/data/config/$id';

  // place holder
  static readonly NAV_DATA_ID_PLACE_HOLDER = '$id';

  // Admin Module
  static readonly SIDE_NAV_MODULE_ADMIN_TITLE = 'sideNav.modules.admin.title';
  static readonly SIDE_NAV_MODULE_ADMIN_ICON = 'Settings24';

  // Admin Sub Module
  static readonly SIDE_NAV_SUB_MODULE_ADMIN_RESOLVE = 'admin/resolve';
  static readonly SIDE_NAV_SUB_MODULE_RESOLVE_DATA =
    'sideNav.modules.admin.subModules.resolveUnsupportedData';
  static readonly SIDE_NAV_SUB_MODULE_RESOLVE_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/${NavigationConstants.SIDE_NAV_SUB_MODULE_ADMIN_RESOLVE}`;

  //Admin Access Rights
  static readonly SIDE_NAV_SUB_MODULE_ACCESS_RIGHTS =
    'sideNav.modules.admin.subModules.accessRights';
  static readonly SIDE_NAV_SUB_MODULE_ACCESS_RIGHTS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/admin/access-rights`;

  // Services Module
  static readonly SIDE_NAV_MODULE_FABRICATION_SERVICES_TITLE = 'sideNav.modules.services.title';
  static readonly SIDE_NAV_MODULE_FABRICATION_SERVICES_ICON = 'Service24';

  // Services (Service Submodule)
  static readonly SIDE_NAV_SUB_MODULE_SERVICES_TITLE =
    'sideNav.modules.services.subModules.services';
  static readonly SIDE_NAV_SUB_MODULE_SERVICES_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/systems`;

  // Service Templates (Service Submodule)
  static readonly SIDE_NAV_SUB_MODULE_SERVICE_TEMPLATES_TITLE =
    'sideNav.modules.services.subModules.serviceTemplates';
  static readonly SIDE_NAV_SUB_MODULE_SERVICE_TEMPLATES_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/service-templates`;

  // specifications module
  static readonly SIDE_NAV_MODULE_SPECIFICATIONS_TITLE = 'sideNav.modules.specifications.title';
  static readonly SIDE_NAV_MODULE_SPECIFICATIONS_ICON = 'Properties24';

  // material specs
  static readonly SIDE_NAV_SUB_MODULE_MATERIAL_SPECS_TITLE =
    'sideNav.modules.specifications.subModules.materialSpecifications';
  static readonly SIDE_NAV_SUB_MODULE_MATERIAL_SPECS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/material-specifications`;

  static readonly SIDE_NAV_SUB_MODULE_PART_SPECS_TITLE =
    'sideNav.modules.specifications.subModules.partSpecifications';
  static readonly SIDE_NAV_SUB_MODULE_PART_SPECS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/specifications`;

  static readonly SIDE_NAV_SUB_MODULE_INS_SPECS_TITLE =
    'sideNav.modules.specifications.subModules.insSpecifications';
  static readonly SIDE_NAV_SUB_MODULE_INS_SPECS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/insulation-specifications`;

  // stiffener specs
  static readonly SIDE_NAV_SUB_MODULE_STIFFENER_SPECS_TITLE =
    'sideNav.modules.specifications.subModules.stiffenerSpecifications';
  static readonly SIDE_NAV_SUB_MODULE_STIFFENER_SPECS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/stiffener-specifications`;

  // database components
  static readonly SIDE_NAV_MODULE_DATABASE_COMPONENTS_TITLE =
    'sideNav.modules.databaseComponents.title';
  static readonly SIDE_NAV_MODULE_DATABASE_COMPONENTS_ICON = 'FileAssembly24';

  // connectors (Database Component Submodule)
  static readonly SIDE_NAV_SUB_MODULE_CONNECTORS_TITLE =
    'sideNav.modules.databaseComponents.subModules.connectors';
  static readonly SIDE_NAV_SUB_MODULE_CONNECTORS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/connectors`;

  // dampers (Database Component Submodule)
  static readonly SIDE_NAV_SUB_MODULE_DAMPERS_TITLE =
    'sideNav.modules.databaseComponents.subModules.dampers';
  static readonly SIDE_NAV_SUB_MODULE_DAMPERS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/dampers`;

  // parts (Database Component Submodule)
  static readonly SIDE_NAV_SUB_MODULE_PARTS_TITLE =
    'sideNav.modules.databaseComponents.subModules.parts';
  static readonly SIDE_NAV_SUB_MODULE_PARTS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/parts`;

  // stiffeners (Database Component Submodule)
  static readonly SIDE_NAV_SUB_MODULE_STIFFENERS_TITLE =
    'sideNav.modules.databaseComponents.subModules.stiffeners';
  static readonly SIDE_NAV_SUB_MODULE_STIFFENERS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/stiffeners`;

  // materials (Database Component Submodule)
  static readonly SIDE_NAV_SUB_MODULE_MATERIALS_TITLE =
    'sideNav.modules.databaseComponents.subModules.materials';
  static readonly SIDE_NAV_SUB_MODULE_MATERIALS_LINK = `/data/config/${NavigationConstants.NAV_DATA_ID_PLACE_HOLDER}/materials`;

  // about (Help Submodule)
  static readonly SIDE_NAV_SUB_MODULE_ABOUT_TITLE = 'sideNav.modules.help.subModules.about';
  static readonly SIDE_NAV_SUB_MODULE_ABOUT_LINK = '/data/help/about';

  // feedback, release notes etc.
  static readonly SIDE_NAV_SUB_MODULE_FEEDBACK_TITLE = 'sideNav.modules.help.subModules.feedback';
  static readonly SIDE_NAV_SUB_MODULE_CONTACT_SUPPORT_TITLE =
    'sideNav.modules.help.subModules.contactSupport';
  static readonly SIDE_NAV_SUB_MODULE_CONTACT_SUPPORT_LINK = `${NavigationConstants.AUTODESK_LINK}support/contact-support`;
  static readonly SIDE_NAV_SUB_MODULE_RELEASE_NOTES_TITLE =
    'sideNav.modules.help.subModules.releaseNotes';
  static readonly SIDE_NAV_SUB_MODULE_PRIVACY_SETTINGS_TITLE =
    'sideNav.modules.help.subModules.privacySettings';

  //terms of use (Help Submodule)
  static readonly SIDE_NAV_SUB_MODULE_TERMS_TITLE = 'sideNav.modules.help.subModules.termsOfUse';
  static readonly SIDE_NAV_SUB_MODULE_TERMS_LINK = '/data/help/terms';
  static readonly SIDE_NAV_SUB_LINK_PRODUCTION_TERMS_LINK = `${NavigationConstants.AUTODESK_LINK}company/terms-of-use/en/general-terms`;

  // product link
  static readonly SIDE_NAV_LINK_PRODUCT_PAGE_TITLE = 'sideNav.links.autodeskFabrication';
  static readonly SIDE_NAV_LINK_PRODUCT_PAGE_LINK =
    'https://www.autodesk.co.uk/products/fabrication-products/overview';

  // privacy and cookies
  static readonly SIDE_NAV_LINK_PRIVACY_COOKIES_TITLE = 'sideNav.links.privacy';
  static readonly SIDE_NAV_LINK_PRIVACY_COOKIES_LINK = `${NavigationConstants.AUTODESK_LINK}company/legal-notices-trademarks/privacy-statement`;

  // help
  static readonly SIDE_NAV_HELP_TITLE = 'sideNav.modules.help.subModules.help';
  static readonly SIDE_NAV_HELP_LINK_STG = `https://help-staging.autodesk.com/view/MEPCE/ENU/`;
  static readonly SIDE_NAV_HELP_LINK_PROD = `https://help.autodesk.com/view/MEPCE/ENU/`;

  static readonly PAGE_NOT_FOUND_LINK = '/data/404';
  static readonly NO_ENTITLEMENT_LINK = `/data/${ErrorPageType.NoEntitlement}`;
  static readonly GETTING_STARTED_LINK = `/data/${ErrorPageType.NoData}`;
  static readonly APP_UNAVAILABLE = `/data/${ErrorPageType.AppUnavailable}`;
  static readonly APP_LOADING_ERROR = `/data/${ErrorPageType.AppLoadingError}`;
  static readonly APP_ABOUT_LINK = '/data/help/about';
}
