import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BinaryStorageService } from '@cache/binary-storage.service';
import { Observable, of, defer, combineLatest, iif } from 'rxjs';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { NotificationType } from '@models/notification/notification';
import { AuthService } from '../auth.service';
import { NotificationService } from '../notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants } from '@constants/localisation-constants';
import { EnvironmentService } from '@services/environment.service';
import helpLinks from '@assets/help/help-links.json';
import { CacheService } from '../cache.service';
import { AppConstants } from '@constants/app-constants';

/**
 * Checks for Service Worker updates
 * @export
 * @class AppUpdateService
 */
@Injectable({
  providedIn: 'root',
})
export class AppUpdateService {
  private logPrefix = 'App Update Service Log: ';
  private appUpdateStorageKey = 'fdm-version-update';

  public get appIsUpdated(): boolean {
    return !!localStorage.getItem(this.appUpdateStorageKey);
  }

  constructor(
    private swUpdate: SwUpdate,
    private binaryStorageService: BinaryStorageService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translate: TranslateService,
    private envService: EnvironmentService,
    private cacheService: CacheService
  ) {}

  public checkForAppUpdates = (): Observable<boolean> => {
    if (!this.swUpdate.isEnabled) {
      return of(true);
    }

    this.swUpdate.checkForUpdate().then((hasUpdate: boolean) => {
      if (hasUpdate) {
        this.activateVersionUpdate().pipe(take(1)).subscribe();
      }
    });
    return of(true);
  };

  public activateVersionUpdate(): Observable<boolean> {
    const activateAppUpdate$ = defer(() => this.swUpdate.activateUpdate());
    return combineLatest([
      this.binaryStorageService.cleanBinaryStorage(),
      this.cacheService.cleanDataCacheStorageForVersionUpdate(),
    ]).pipe(
      map((results: [boolean, boolean]) => results.every((x) => x === true)),
      switchMap((storageCleared: boolean) =>
        iif(() => storageCleared, activateAppUpdate$, of(false))
      ),
      tap((ready: boolean) => {
        if (ready) {
          console.log(`${this.logPrefix}Service Worker activated update`);
          this.setVersionUpdatedStorageItem();
          this.authService.navigateToSignInWindow();
        } else {
          console.error(`${this.logPrefix}Service Worker failed to activated update`);
        }
      })
    );
  }

  private clearVersionUpdateStorageKey = (): void => {
    localStorage.removeItem(this.appUpdateStorageKey);
  };

  public notifyVersionUpdateIfAvailable = (): void => {
    // display version update banner if required
    // delay to avoid showing too early prior to the browser refresh
    // triggered by the new angular version
    of(this.appIsUpdated)
      .pipe(delay(5000), take(1))
      .subscribe((isUpdated: boolean) => {
        if (isUpdated) {
          const message = this.translate.instant(
            LocalisationConstants.NOTIFICATIONS.VERSION_UPDATE.MESSAGE,
            { appName: AppConstants.APP_NAME }
          );
          this.notificationService.showBanner(
            message,
            NotificationType.Info,
            null,
            this.clearVersionUpdateStorageKey,
            [
              {
                title: this.translate.instant(
                  LocalisationConstants.NOTIFICATIONS.COMMON.LEARN_MORE
                ),
                onClick: () => {
                  window.open(
                    `${this.envService.environment.helpLinks.baseUrl}${helpLinks.general.releaseNotes}`,
                    '_blank'
                  );
                },
              },
            ]
          );
        }
      });
  };

  private setVersionUpdatedStorageItem = (): void => {
    localStorage.setItem(this.appUpdateStorageKey, '1');
  };
}
