export const ApplicationLangs = ['de', 'en', 'es', 'fr', 'it'];

export class LocalisationConstants {
  static readonly APP = {
    ERROR_ENVIRONMENT_SETTINGS: 'application.errors.environmentSettings',
  };

  static readonly VALIDATIONS = {
    MSG_ALREADY_EXIST: 'application.validations.msgAlreadyExist',
    MSG_UNIQUE_COMBINATION_REQUIRED: 'application.validations.msgUniqueCombinationRequired',
    FORM: {
      REQUIRED: 'application.validations.form.required',
      MIN_LENGTH: 'application.validations.form.minLength',
      MAX_LENGTH: 'application.validations.form.maxLength',
      MIN: 'application.validations.form.min',
      MAX: 'application.validations.form.max',
      UNIQUE_MODEL_SINGLE: 'application.validations.form.uniqueModelSingle',
      UNIQUE_MODEL_PLURAL: 'application.validations.form.uniqueModelPlural',
      VALID: 'application.validations.form.valid',
      OWNER_EMAIL_ADDRESS: 'application.validations.form.ownerEmailAddres',
      PROHIBITED_CHARACTERS: 'application.validations.form.prohibitedCharacters',
    },
  };

  static readonly COMMON = {
    ADD: 'common.add',
    EDIT: 'common.edit',
    APPLY: 'common.apply',
    CANCEL: 'common.cancel',
    PROCEED: 'common.proceed',
    DUPLICATE: 'common.duplicate',
    DELETE: 'common.delete',
  };

  static readonly NOTIFICATIONS = {
    COMMON: {
      LEARN_MORE: 'notifications.common.learnMore',
      SOMETHING_WENT_WRONG: 'notifications.common.somethingWentWrong',
    },
    ERROR_INTERNAL_OPERATION: 'notifications.internalOperation',
    ERROR_LOADING_FORGE_UNITS: 'notifications.loadingForgeUnits',
    ERROR_GETTING_CONFIG_META_DATA: 'notifications.gettingConfigMetaData',
    ERROR_READING_CONFIG_DATA: 'config.errors.readingConfigData',
    ERROR_DELETE_CONFIG: 'notifications.deleteConfig',
    MSG_DELETE_DATA_ELEMENT: 'notifications.msgDeleteDataElement',
    MSG_CHANGE_BREAKPOINT_TABLE_TYPE: 'notifications.msgChangeBreakpointTableType',
    TITLE_CHANGE_BREAKPOINT_TABLE_TYPE: 'notifications.titleChangeBreakpointTableType',
    MSG_DATA_ELEMENT_FAILURE: 'notifications.msgDataElementFailure',
    MSG_SIZE_RESTRICTIONS_UNABLE_DELETE: 'notifications.msgSizeRestrictionsUnableDelete',
    MSG_SIZE_RESTRICTIONS_UNABLE_DELETE_PLURAL:
      'notifications.msgSizeRestrictionsUnableDeletePlural',
    MSG_SIZE_RESTRICTIONS_UNABLE_DELETE_SINGULAR:
      'notifications.msgSizeRestrictionsUnableDeleteSingular',
    MSG_SIZE_RESTRICTIONS_UNABLE_DELETE_IN_USE:
      'notifications.msgSizeRestrictionsUnableDeleteInUse',
    MSG_LOGGING_SERVICE_GENERIC_ERROR: 'notifications.msgLogginServiceGenericError',
    MSG_DYNAMIC_PART_VIEWER_ERROR: 'notifications.msgDynamicPartViewerError',
    MSG_DYNAMIC_GRAPH_ERROR: 'notifications.msgDynamicGraphError',
    ERROR_AUTH_FAILED: 'notifications.authFailed',
    MSG_AUTH_ERROR_RESPONSE: 'notifications.msgAuthErrorResponse',
    MSG_AUTH_ERROR_RESPONSE_TRY_AGAIN: 'notifications.msgAuthErrorResponseTryAgain',
    MSG_DATA_ELEMENT_DELETE_RESTRICTION: 'notifications.msgDataElementDeleteRestriction',
    MSG_DATA_ELEMENT_DELETE_RESTRICTION_SINGULAR:
      'notifications.msgDataElementDeleteRestrictionSingular',
    MSG_DATA_ELEMENT_SUCCESS: 'notifications.msgDataElementSuccess',
    MSG_DATA_ELEMENT_SUCCESS_FLYOUT: 'notifications.msgDataElementSuccessFlyout',
    MSG_DATA_ELEMENT_POST_FAILURE: 'notifications.msgDataElementPostFailure',
    MSG_DATA_ELEMENT_ERROR: 'notifications.msgDataElementError',
    MSG_DATA_ELEMENT_ERROR_DETAILS: 'notifications.msgDataElementErrorDetails',
    MSG_PART_LOAD_FAILURE: 'notifications.msgDataPartLoadFailure',
    DIALOG: {
      BTN_CONTINUE: 'notifications.dialog.btnContinue',
      BTN_CANCEL: 'notifications.dialog.btnCancel',
    },
    MODAL: {
      MSG_NAVIGATE_AWAY_TITLE: 'notifications.modal.msgNavigateAwayTitle',
      MSG_NAVIGATE_AWAY_DETAIL: 'notifications.modal.msgNavigateAwayDetail',
      MSG_COULD_NOT_APPLY: 'notifications.modal.msgCouldNotApply',
      MSG_COULD_NOT_APPLY_DETAIL: 'notifications.modal.msgCouldNotApplyDetail',
      BTN_LEAVE: 'notifications.modal.btnLeave',
      BTN_STAY: 'notifications.modal.btnStay',
      BTN_REFRESH_PAGE: 'notifications.modal.btnRefreshPage',
    },
    ACCESS_RIGHTS: {
      MSG_LOAD_ROLE_FAILED: 'notifications.accessRights.msgLoadRoleFailed',
      MSG_ACCESS_GRANTED: 'notifications.accessRights.msgAccessGranted',
      MSG_ACCESS_REVOKED: 'notifications.accessRights.msgAccessRevoked',
      LINK_ACCESS_GRANTED: 'notifications.accessRights.linkAccessGranted',
      TITLE_PERMISSION_CHANGED: 'notifications.accessRights.titlePermissionChanged',
      MSG_PERMISSION_CHANGED: 'notifications.accessRights.msgPermissionChanged',
      TITLE_DATA_REMOVED: 'notifications.accessRights.titleDataRemoved',
      MSG_DATA_REMOVED: 'notifications.accessRights.msgDataRemoved',
      MSG_DATA_REMOVED_DETAILED: 'notifications.accessRights.msgDataRemovedDetailed',
    },
    SESSION_LOGGED_OUT: {
      TITLE: 'notifications.sessionLoggedOut.title',
      MESSAGE: 'notifications.sessionLoggedOut.message',
    },
    VERSION_UPDATE: {
      MESSAGE: 'notifications.versionUpdate.message',
    },
    THUMBNAIL_EDITOR: {
      GENERATED: 'notifications.thumbnailEditor.generated',
      UPLOADED: 'notifications.thumbnailEditor.uploaded',
      LARGE_FILE: 'notifications.thumbnailEditor.largeFile',
      UPLOAD_FAILED: 'notifications.thumbnailEditor.uploadFailed',
      INVALID_IMAGE: 'notifications.thumbnailEditor.invalidImage',
    },
    OLD_CONFIG_BANNER: {
      TITLE: 'notifications.oldConfigBanner.title',
    },
    COMPLETE_OPERATIONS: {
      ADD: 'notifications.completeOperations.add',
      ADDED: 'notifications.completeOperations.added',
      COPY: 'notifications.completeOperations.copy',
      COPIED: 'notifications.completeOperations.copied',
      DELETE: 'notifications.completeOperations.delete',
      DELETED: 'notifications.completeOperations.deleted',
      FIXED: 'notifications.completeOperations.fixed',
      UPDATE: 'notifications.completeOperations.update',
      UPDATED: 'notifications.completeOperations.updated',
      UPGRADED: 'notifications.completeOperations.upgraded',
    },
    FAILED_OPERATIONS: {
      ADDING: 'notifications.failedOperations.adding',
      COPYING: 'notifications.failedOperations.copying',
      DELETING: 'notifications.failedOperations.deleting',
      FIXING: 'notifications.failedOperations.fixing',
      UPDATING: 'notifications.failedOperations.updating',
      UPGRADING: 'notifications.failedOperations.upgrading',
    },
  };

  static readonly LOADER = {
    LOADING_RESULTS: 'loader.loadingResults',
    LOADING_MORE: 'loader.loadingMore',
  };

  static readonly CONFIG = {
    ERROR_LOADING_FABDM_WEB_ASSEMBLY_ENGINE: 'config.errors.loadingFabdmWebAssemblyEngine',
    ERROR_LOADING_FABRICATION_DATABASE: 'config.errors.loadingFabricationDatabase',
    ERROR_LOADING_FABRICATION_DATABASE_BINARY_FILES:
      'config.errors.loadingFabricationDatabaseBinaryFiles',
    ERROR_LOADING_FABRICATION_PART: 'config.errors.loadingFabricationPart',
    ERROR_LOADING_FABRICATION_PART_GEOMETRY: 'config.errors.loadingFabricationPartGeometry',
  };

  static readonly TOOLTIP = {
    CONFIG_INFO_DELETE: 'tooltip.configInfo.delete',
    PART_FOLDER_CONTENT_CLEAR: 'tooltip.partFolderContent.clear',
    PART_FOLDER_CONTENT_EDIT: 'tooltip.partFolderContent.edit',
    PART_FOLDER_CONTENT_COPY: 'tooltip.partFolderContent.copy',
    PART_FOLDER_CONTENT_VIEW: 'tooltip.partFolderContent.view',
    PART_FOLDER_CONTENT_DELETE: 'tooltip.partFolderContent.delete',
    PART_FOLDER_CONTENT_RELATIONSHIPS: 'tooltip.partFolderContent.relationships',
    PART_FOLDER_CONTENT_UPGRADE: 'tooltip.partFolderContent.upgrade',
    PART_FOLDER_CONTENT_UPGRADE_DISABLE_FOR_READ: 'tooltip.partFolderContent.upgradeDisableForRead',
    PART_FOLDER_CONTENT_UPGRADING: 'tooltip.partFolderContent.upgrading',
    PART_FOLDER_CONTENT_DUPLICATE: 'tooltip.partFolderContent.duplicate',
    VIEW: {
      LIST: 'tooltip.view.list',
      TILED: 'tooltip.view.tiled',
    },
    CONNECTOR: {
      READONLY_FIELD: 'tooltip.connector.readonlyField',
      REFERENCED_AS_MATCHING_CONNECTOR_SINGULAR:
        'tooltip.connector.referencedAsMatchingConnectorSingular',
      REFERENCED_AS_MATCHING_CONNECTOR_PLURAL:
        'tooltip.connector.referencedAsMatchingConnectorPlural',
      REMOVE_MATCHING_CONNECTOR: 'tooltip.connector.removeMatchingConnector',
    },
    PART_EDIT: 'tooltip.part.edit',
    PART_COPY: 'tooltip.part.copy',
    PART_RELATIONSHIPS: 'tooltip.part.relationships',
    PART_VIEW: 'tooltip.part.view',
    PART_DELETE: 'tooltip.part.delete',
    PART_UPGRADE: 'tooltip.part.upgrade',
    PART_DISABLE_FOR_READ: 'tooltip.part.upgradeDisableForRead',
    ADD_PART_TO_SIZE_RESTRICTION: 'tooltip.addPartToSizeRestriction',
    EDIT_PART_COLLECTION: 'tooltip.editPartCollection',
    MOVE_PART_LEFT: 'tooltip.movePartLeft',
    MOVE_PART_RIGHT: 'tooltip.movePartRight',
    TOGGLE_PART_EXCLUSION: 'tooltip.togglePartExclusion',
    DELETE_PART: 'tooltip.deletePart',
    REMOVE_PART_REFERENCE: 'tooltip.removePartReference',
    REMOVE_PART_REFERENCES: 'tooltip.removePartReferences',
    DELETE_DISABLED: 'tooltip.deleteDisabled',
    DELETE: 'tooltip.delete',
    RELATIONSHIPS: 'tooltip.relationships',
    EDIT: 'tooltip.edit',
    ADD: 'tooltip.add',
    COPY: 'tooltip.copy',
    MOVE_UP: 'tooltip.moveUp',
    MOVE_DOWN: 'tooltip.moveDown',
    ADD_PART_TEMPLATE_PALETTE: 'tooltip.addPartTemplatePalette',
    ADD_PART_TEMPLATE_PARTS: 'tooltip.addPartTemplateParts',
    DUPLICATE: 'tooltip.duplicate',
  };

  static readonly TOOLBAR = {
    ADD_PART: 'toolbar.addParts',
    ADD_PART_TO_SIZE_RESTRICTION: 'toolbar.addPartToSizeRestriction',
    TOGGLE_PART_EXCLUSION: 'toolbar.togglePartExclusion',
    MOVE_PART_LEF: 'toolbar.movePartLeft',
    MOVE_PART_RIGH: 'toolbar.movePartRight',
    EDIT_PART_SIZE_RESTRICTIONS: 'toolbar.editPartSizeRestrictions',
    DELETE_PART: 'toolbar.deletePart',
    EDIT_PART_COLLECTION: 'toolbar.editPartCollection',
    PART_EDIT: 'toolbar.part.edit',
    PART_DELETE: 'toolbar.part.delete',
    PART_DUPLICATE: 'toolbar.part.duplicate',
    PART_RELATIONSHIPS: 'toolbar.part.relationships',
    PART_UPGRADE: 'toolbar.part.upgrade',
    PART_VIEW: 'toolbar.part.view',
    PART_EDITOR: {
      ADD_PRODUCT_ENTRY: 'toolbar.partEditor.addProductEntry',
    },
    LABEL: {
      ADD: 'toolbar.label.add',
      MOVE_UP: 'toolbar.label.moveUp',
      MOVE_DOWN: 'toolbar.label.moveDown',
      DELETE: 'toolbar.label.delete',
      ADD_TO_SIZE_RESTRICTION: 'toolbar.label.addToSizeRestriction',
      EDIT: 'toolbar.label.edit',
      MOVE_LEFT: 'toolbar.label.moveLeft',
      MOVE_RIGHT: 'toolbar.label.moveRight',
      EXCLUDE: 'toolbar.label.exclude',
      RELATIONSHIPS: 'toolbar.label.relationships',
      DUPLICATE: 'toolbar.label.duplicate',
      VIEW: 'toolbar.label.visible',
    },
  };

  static readonly MULTI_SELECT = {
    NOT_ASSIGNED: 'multiSelect.notAssigned',
    SELECTED: 'multiSelect.selected',
    REFERENCES_EXIST: 'multiSelect.referencesExist',
    SEARCH: 'multiSelect.search',
    NO_MATCHES: 'multiSelect.noMatches',
  };

  static readonly PART_BROWSER = {
    ADD_PART: 'partBrowser.btnAdd',
    CANCEL_PART: 'partBrowser.btnCancel',
    TOTAL_SELECTED: 'partBrowser.totalSelected',
    NO_ITEM_FOLDERS: 'partBrowser.pNoItemFolders',
    TEMP_MODEL_USER_INSTRUCTIONS: 'partBrowser.tempModelUserInstructions',
    TEMP_MODEL_SINGULAR: 'partBrowser.tempModelSingular',
    TEMP_MODEL_PLURAL: 'partBrowser.tempModelPlural',
    SERVICE_TEMPLATE_MESSAGE: {
      PALLET: 'partBrowser.partTemplateMessage.pallet',
      USING: 'partBrowser.partTemplateMessage.using',
      SIZE: 'partBrowser.partTemplateMessage.size',
      COLLECTION: 'partBrowser.partTemplateMessage.collection',
    },
    SELECT_ALL: 'partBrowser.selectAll',
    PARTS: 'partBrowser.parts',
    NO_PARTS_IN_FOLDER: 'partFolderContents.noPartsInFolder',
    NO_PARTS_FOUND: {
      TITLE: 'noPartsFound.title',
      DESCRIPTION: 'noPartsFound.description',
      ACTION_LABEL: 'noPartsFound.actionLabel',
    },
    PART_PAGINATION: {
      SHOWING: 'partPagination.showing',
      OF: 'partPagination.of',
      RESULTS: 'partPagination.results',
      SELECTED: 'partPagination.selected',
    },
    PART_FILTER: {
      TITLE: 'partFilter.title',
      CLEAR_ALL: 'partFilter.clearAll',
      SEARCH_PART: 'partFilter.searchPart',
      BRAND_FILTER: 'partFilter.brandFilter',
      RANGE_FILTER: 'partFilter.rangeFilter',
      MATERIAL_FILTER: 'partFilter.materialFilter',
      INSTALL_TYPE_FILTER: 'partFilter.installTypeFilter',
      FINISH_FILTER: 'partFilter.finishFilter',
      PATTERN_NUMBER_FILTER: 'partFilter.patternNumber',
    },
  };

  static readonly FEATURE_MODULES = {
    CONFIG_INFO: {
      DELETE_CONFIGURATION: 'featureModules.configInfo.deleteConfiguration',
      BTN_OK: 'featureModules.configInfo.btnOk',
      BTN_CANCEL: 'featureModules.configInfo.btnCancel',
      DELETE_CONFIG_MESSAGE: 'featureModules.configInfo.deleteConfigMessage',
      CONFIG_ACCESS_RIGHTS: 'featureModules.configInfo.configAccessRights',
    },
    SERVICE_TEMPLATES: {
      PARTS: {
        MODAL_TITLE: 'featureModules.partTemplates.parts.modalTitle',
        UNABLE_TO_ADD_PART: 'featureModules.partTemplates.parts.unableToAddPart',
        UNABLE_TO_ADD_PART_MESSAGE: 'featureModules.partTemplates.parts.unableToAddPartMessage',
        BTN_CLOSE: 'featureModules.partTemplates.parts.btnClose',
        SELECT_PALETTE: 'featureModules.partTemplates.parts.selectPalette',
        SELECT_SIZE_RESTRICTION: 'featureModules.partTemplates.parts.selectSizeRestriction',
        NO_DATA: 'featureModules.partTemplates.parts.noData',
        PART_COLLECTIONS: {
          MODAL_TITLE: 'featureModules.partTemplates.parts.partCollections.modalTitle',
          NAME: 'featureModules.partTemplates.parts.partCollections.name',
        },
      },
      SIZE_RESTRICTIONS: {
        DESCRIPTION: 'featureModules.partTemplates.sizeRestrictions.description',
        GREATER_THAN: 'featureModules.partTemplates.sizeRestrictions.greaterThan',
        LESS_THAN_OR_EQUAL: 'featureModules.partTemplates.sizeRestrictions.lessThanOrEqual',
        UNRESTRICTED: 'featureModules.partTemplates.sizeRestrictions.unrestricted',
        RANGE_OUT: 'featureModules.partTemplates.sizeRestrictions.rangeOut',
        POSITIVE_RANGE: 'featureModules.partTemplates.sizeRestrictions.positiveRange',
      },
    },
    MATERIAL_SPECIFICATIONS: {
      INSIDE_OUTSIDE_DIAMETER_RANGE_OUT:
        'featureModules.materialSpecifications.insideOutsideDiameterRangeOut',
    },
    SPECIFICATIONS: {
      MODAL_TITLE: 'featureModules.specifications.modalTitle',
    },
    INSULATION_SPECIFICATIONS: {
      MODAL_TITLE: 'featureModules.insulationSpecifications.modalTitle',
    },
    STIFFENER_SPECIFICATIONS: {
      MODAL_TITLE: 'featureModules.stiffenerSpecifications.modalTitle',
    },
  };

  static readonly PAGE_NOT_FOUND = {
    TITLE: 'pageNotFound.title',
    DESCRIPTION: 'pageNotFound.description',
    ACTIONS_CLICK: 'pageNotFound.actions.click',
    ACTIONS_LINK: 'pageNotFound.actions.link',
    ACTIONS_TEXT: 'pageNotFound.actions.text',
  };

  static readonly MISSING_SETTINGS = {
    DEFAULT_MESSAGE: 'missingSettings.defaultMessage',
  };

  static readonly GETTING_STARTED = {
    DEFAULT_MESSAGE: 'gettingStarted.defaultMessage',
    LEARN_HOW: 'gettingStarted.learnHow',
  };

  static readonly NO_ENTITLEMENT = {
    DEFAULT_MESSAGE: 'noEntitlement.defaultMessage',
  };

  static readonly APP_UNAVAILABLE = {
    DEFAULT_MESSAGE: 'appUnavailable.defaultMessage',
  };

  static readonly APP_LOADING_ERROR = {
    DEFAULT_MESSAGE: 'appLoading.defaultMessage',
  };

  static readonly DYNAMIC_TABLE = {
    NO_DATA: 'dynamicTable.noData',
    CLEAR: 'dynamicTable.clear',
    NA: 'dynamicTable.na',
    YES: 'dynamicTable.yes',
    NO: 'dynamicTable.no',
    PAGINATOR: {
      ITEMS_PER_PAGE: 'dynamicTable.paginator.itemsPerPage',
      NEXT_PAGE: 'dynamicTable.paginator.nextPage',
      PREVIOUS_PAGE: 'dynamicTable.paginator.previousPage',
      FROM: 'dynamicTable.paginator.from',
    },
    PIPEWORK_TABLES_ONLY: 'dynamicTable.pipeworkTablesOnly',
    MODAL: {
      DELETE_ITEM: {
        HEADER: 'dynamicTable.modal.deleteItem.header',
        CANCEL: 'dynamicTable.modal.deleteItem.cancel',
        DELETE: 'dynamicTable.modal.deleteItem.delete',
        SINGLE_CONTENT: 'dynamicTable.modal.deleteItem.singleContent',
        MULTIPLE_CONTENT: 'dynamicTable.modal.deleteItem.multipleContent',
      },
    },
    EDIT_COLUMNS: 'dynamicTable.editColumns',
  };

  static readonly DATATYPES = {
    TYPES: {
      CONNECTOR: 'datatypes.types.connector',
      DAMPER: 'datatypes.types.damper',
      STIFFENER: 'datatypes.types.stiffener',
      MATERIAL: 'datatypes.types.material',
      MATERIAL_SPECIFICATION: 'datatypes.types.materialSpecification',
      PART: 'datatypes.types.part',
      // connected to property PartTemplate in the DataElementType enum
      SERVICE_TEMPLATE: 'datatypes.types.serviceTemplate',
      INSTALLATION_RATE: 'datatypes.types.installationRate',
      FABRICATION_RATE: 'datatypes.types.fabricationRate',
      SERVICE: 'datatypes.types.service',
      INSULATION_SPECIFICATION: 'datatypes.types.insulationSpecification',
      SPECIFICATION: 'datatypes.types.specification',
      NO_DATA_TYPE: 'datatypes.types.noDataType',
      PALETTE: 'datatypes.types.palette',
      TABLE: 'datatypes.types.table',
      PART_SPECIFICATION_TABLE: 'datatypes.types.partSpecTable',
      STIFFENER_SPECIFICATION: 'datatypes.types.stiffenerSpecification',
    },
    TITLES: {
      // connected to property PartTemplate in the DataElementType enum
      SERVICE_TEMPLATES: 'datatypes.titles.serviceTemplates',
      PARTS: 'datatypes.titles.parts',
      SERVICES: 'datatypes.titles.services',
      CONNECTORS: 'datatypes.titles.connectors',
      DAMPERS: 'datatypes.titles.dampers',
      STIFFENERS: 'datatypes.titles.stiffeners',
      MATERIALS: 'datatypes.titles.materials',
      MATERIAL_SPECIFICATIONS: 'datatypes.titles.materialSpecifications',
      SPECIFICATIONS: 'datatypes.titles.specifications',
      INSULATION_SPECIFICATIONS: 'datatypes.titles.insulationSpecifications',
      DB_FILES: 'datatypes.titles.dBFiles',
      FABRICATION_RATES: 'datatypes.titles.fabricationRates',
      INSTALLATION_RATES: 'datatypes.titles.installationRates',
      INVALID_DATAS: 'datatypes.titles.invalidDatas',
      PERMISSIONS: 'datatypes.titles.permissions',
      STIFFENER_SPECIFICATIONS: 'datatypes.titles.stiffenerSpecifications',
      PART_SPECIFICATION_TABLES: 'datatypes.titles.partSpecTables',
    },
    DEFINITIONS: {
      // todo: remove repeated definitions of the same strings (DRY)
      COMMON: {
        ABBREVIATION: 'datatypes.definitions.common.abbreviation',
        ANY: 'datatypes.definitions.common.any',
        CATEGORY: 'datatypes.definitions.common.category',
        CONNECTIVITY: 'datatypes.definitions.common.connectivity',
        NAME: 'datatypes.definitions.common.description',
        DEPTH: 'datatypes.definitions.common.depth',
        DOMAIN: 'datatypes.definitions.common.domain',
        ERROR: 'datatypes.definitions.common.error',
        GROUP: 'datatypes.definitions.common.group',
        LENGTH: 'datatypes.definitions.common.length',
        NONE: 'datatypes.definitions.common.none',
        NOT_SET: 'datatypes.definitions.common.notSet',
        TYPE: 'datatypes.definitions.common.type',
        SHAPE: 'datatypes.definitions.common.shape',
        MAX_SIZE: 'datatypes.definitions.common.maxSize',
        THICKNESS: 'datatypes.definitions.common.thickness',
        WIDTH: 'datatypes.definitions.common.width',
        EMAIL_ID: 'datatypes.definitions.common.emailId',
        ROLE: 'datatypes.definitions.common.role',
        ADDITIONAL_ACCESS: 'datatypes.definitions.common.additionalAccess',
        PRODUCT_ENTRY: 'datatypes.definitions.common.productEntry',
        PRODUCT_DATA: 'datatypes.definitions.common.productData',
        ORDER_NUMBER: 'datatypes.definitions.common.orderNumber',
        WEIGHT: 'datatypes.definitions.common.weight',
        ID: 'datatypes.definitions.common.id',
      },
      SERVICES: {
        MODEL_BY: 'datatypes.definitions.services.modelBy',
        HANGER_ENGAGEMENT: 'datatypes.definitions.services.hangerEngagement',
        INSULATION_TYPE: 'datatypes.definitions.services.insulationType',
        BASIC: 'datatypes.definitions.services.basic',
        INSULATION_SETTINGS: 'datatypes.definitions.services.insulationSettings',
      },
      PARTS: {
        ALIAS: 'datatypes.definitions.parts.alias',
        NAME: 'datatypes.definitions.parts.description',
        PATTERN_NUMBER: 'datatypes.definitions.parts.patternNumber',
        BRAND: 'datatypes.definitions.parts.brand',
        RANGE: 'datatypes.definitions.parts.range',
        GROUP: 'datatypes.definitions.parts.group',
        SOURCE: 'datatypes.definitions.parts.source',
        OWNER: 'datatypes.definitions.parts.owner',
        DIMENSION: 'datatypes.definitions.parts.dimension',
        PRODUCT_LIST_ENTRIES: 'datatypes.definitions.parts.productListEntries',
        CALCULATED: 'datatypes.definitions.parts.calculated',
        DEPENDENCY: 'datatypes.definitions.parts.dependency',
        DEPENDENCY_NOT_AVAILABLE: 'datatypes.definitions.parts.dependencyNotAvailable',
        PRODUCT_ENTRY_NAME_REQUIRED: 'datatypes.definitions.parts.productEntryNameRequired',
        INVALID_GEOMETRY: 'datatypes.definitions.parts.invalidGeometry',
        INVALID_GEOMETRY_CAUSED_BY: 'datatypes.definitions.parts.invalidGeometryCausedBy',
        SAME_VALUE_ACROSS_ALL_TYPES: 'datatypes.definitions.parts.sameValueUsedAcrossAllTypes',
        UNTITLED: 'datatypes.definitions.parts.untitled',
        READONLY_VALUES: 'datatypes.definitions.parts.readonlyValues',
        EDIT: 'datatypes.definitions.parts.edit',
        EDIT_NAME_ERROR: 'datatypes.definitions.parts.editNameError',
        FINISH: 'datatypes.definitions.parts.finish',
        CONNECTOR_END: 'datatypes.definitions.parts.connectorEnd',
        CONNECTIVITY_END: 'datatypes.definitions.parts.connectivityEnd',
        PRODUCT_NUMBER: 'datatypes.definitions.parts.productNumber',
      },
      SERVICE_TEMPLATES: {
        NAME: 'datatypes.definitions.partTemplates.description',
        GROUP: 'datatypes.definitions.partTemplates.group',
        MATERIAL_TYPE: 'datatypes.definitions.partTemplates.materialType',
        SIZE_RESTRICTION_ID: 'datatypes.definitions.partTemplates.sizeRestrictionId',
        FABRICATION_REFERENCES_0_EXTERNAL_ID:
          'datatypes.definitions.partTemplates.fabricationReferences0ExternalId',
        //shared translation mapping for parts
        PATTERN_NUMBER: 'datatypes.definitions.parts.patternNumber',
        OWNER: 'datatypes.definitions.parts.owner',
        SOURCE: 'datatypes.definitions.parts.source',
        ABBREVIATION: 'datatypes.definitions.parts.abbreviation',
        //shared translation mapping for connectors
        SHAPE: 'datatypes.definitions.connectors.shape',
        DOMAIN: 'datatypes.definitions.connectors.domain',
        GENDER: 'datatypes.definitions.connectors.gender',
        CONNECTIVITY_CLASSIFICATION: 'datatypes.definitions.connectors.connectivityClassification',
        PIPEWORK_END_TYPE: 'datatypes.definitions.connectors.pipeworkEndType',
        PRESSURE_CLASS: 'datatypes.definitions.connectors.pressureClass',
        GEOMETRY_TYPE: 'datatypes.definitions.connectors.geometryType',
        CONNECTOR_LEAD: 'datatypes.definitions.connectors.connectorLead',
        VALUE: 'datatypes.definitions.connectors.value',
        STEP_DOWN: 'datatypes.definitions.connectors.stepDown',
        DEVELOPMENT_TURNOVER: 'datatypes.definitions.connectors.developmentTurnover',
        //shared translation mapping for dampers
        GAUGE: 'datatypes.definitions.dampers.gauge',
        INSET: 'datatypes.definitions.dampers.inset',
        ADJUST: 'datatypes.definitions.dampers.adjust',
        HOLE_ANGLE: 'datatypes.definitions.dampers.holeAngle',
        CONTROL_TYPE: 'datatypes.definitions.dampers.controlType',
        IS_DISPLAYED: 'datatypes.definitions.dampers.isDisplayed',
        IS_MACHINE_CUT: 'datatypes.definitions.dampers.isMachineCut',
        HANDLE_EXTENSION: 'datatypes.definitions.dampers.handleExtension',
        CATEGORY: 'datatypes.definitions.dampers.category',
        //shared translation mapping for stiffeners
        END_TYPE: 'datatypes.definitions.stiffeners.endType',
        LONG_ADJUST: 'datatypes.definitions.stiffeners.longAdjust',
        CENTRAL_TYPE: 'datatypes.definitions.stiffeners.centralType',
        SHORT_ADJUST: 'datatypes.definitions.stiffeners.shortAdjust',
        OMIT_SHORT_SIDE_IF_LESS_THAN: 'datatypes.definitions.stiffeners.omitShortSideIfLessThan',
        //shared translation mapping for materials
        TYPE: 'datatypes.definitions.materials.type',
        MATERIAL_SPECIFICATIONS: 'datatypes.definitions.materials.materialSpecifications',
        //shared translation mapping for material specifications
        FORM: 'datatypes.definitions.materialsSpec.form',
        THICKNESS: 'datatypes.definitions.materialsSpec.thickness',
        WIREGAUGE: 'datatypes.definitions.materialsSpec.wireGauge',
        DELETE: 'datatypes.definitions.partCollections.delete',
        DELETE_DISABLED: 'datatypes.definitions.partCollections.deleteDisabled',
        PART: 'datatypes.definitions.partCollections.part',
        SIZE_RESTRICTION: 'datatypes.definitions.partCollections.sizeRestriction',
      },
      SPECIFICATIONS: {
        FABRICATION_REFERENCES_1_EXTERNAL_ID:
          'datatypes.definitions.specifications.fabricationReferences1ExternalId',
        TABLE_TYPE: 'datatypes.definitions.common.type',
        APPLIES_TO: 'datatypes.definitions.specifications.appliesTo',
        PRIMARY_CONNECTOR: 'datatypes.definitions.specifications.primaryConnector',
        SECONDARY_CONNECTOR: 'datatypes.definitions.specifications.secondaryConnector',
      },
      INSULATION_SPECIFICATIONS: {
        TABLE_TYPE: 'datatypes.definitions.common.type',
        FABRICATION_REFERENCES_2_EXTERNAL_ID:
          'datatypes.definitions.insullationSpecifications.fabricationReferences2ExternalId',
      },
      GENERIC: {
        LAST_MODIFIED: 'datatypes.definitions.generic.lastModified',
        NO_GROUP: 'datatypes.definitions.generic.noGroup',
        NOT_ASSIGNED: 'datatypes.definitions.generic.notAssigned',
        SEARCH_FOR: 'datatypes.definitions.generic.searchFor',
        DATA_TYPE: 'datatypes.definitions.generic.dataType',
      },
      CONNECTORS: {
        NAME: 'datatypes.definitions.connectors.description',
        GROUP: 'datatypes.definitions.connectors.group',
        DOMAIN: 'datatypes.definitions.connectors.domain',
        SHAPE: 'datatypes.definitions.connectors.shape',
        GENDER: 'datatypes.definitions.connectors.gender',
        CONNECTIVITY_CLASSIFICATION: 'datatypes.definitions.connectors.connectivityClassification',
        PIPEWORK_END_TYPE: 'datatypes.definitions.connectors.pipeworkEndType',
        PRESSURE_CLASS: 'datatypes.definitions.connectors.pressureClass',
        MEASUREMENT_MODE: 'datatypes.definitions.connectors.measurementMode',
        GEOMETRY_TYPE: 'datatypes.definitions.connectors.geometryType',
        PATTERN_NUMBER: 'datatypes.definitions.connectors.patternNumber',
        CONNECTOR_LEAD: 'datatypes.definitions.connectors.connectorLead',
        VALUE: 'datatypes.definitions.connectors.value',
        STEP_DOWN: 'datatypes.definitions.connectors.stepDown',
        DEVELOPMENT_TURNOVER: 'datatypes.definitions.connectors.developmentTurnover',
        BODY_LENGTH: 'datatypes.definitions.connectors.bodyLength',
        BODY_SET_BACK: 'datatypes.definitions.connectors.bodySetBack',
        BODY_THICKNESS: 'datatypes.definitions.connectors.bodyThickness',
        DIAMETER: 'datatypes.definitions.connectors.diameter',
        EXTENSION: 'datatypes.definitions.connectors.extension',
        SNAP_OFFSET: 'datatypes.definitions.connectors.snapOffset',
        SWAGE_LENGTH: 'datatypes.definitions.connectors.swageLength',
        SWAGE_SET_BACK: 'datatypes.definitions.connectors.swageSetBack',
        LONG_SIDE: 'datatypes.definitions.connectors.longSide',
        SHORT_SIDE: 'datatypes.definitions.connectors.shortSide',
        GASKET: 'datatypes.definitions.connectors.gasket',
        LINE_1: 'datatypes.definitions.connectors.line1',
        LINE_2: 'datatypes.definitions.connectors.line2',
        LINE_3: 'datatypes.definitions.connectors.line3',
        LINE_4: 'datatypes.definitions.connectors.line4',
        SIDES: 'datatypes.definitions.connectors.sides',
        HORIZONTAL_INSET: 'datatypes.definitions.connectors.horizontalInset',
        LENGTH: 'datatypes.definitions.connectors.length',
        THICKNESS: 'datatypes.definitions.connectors.thickness',
        VERTICAL_WIDTH: 'datatypes.definitions.connectors.verticalWidth',
        WIDTH: 'datatypes.definitions.connectors.width',
        DEPTH: 'datatypes.definitions.connectors.depth',
        HEX_HEIGHT: 'datatypes.definitions.connectors.hexHeight',
        HEX_LENGTH: 'datatypes.definitions.connectors.hexLength',
        HEX_OFFSET: 'datatypes.definitions.connectors.hexOffset',
        MATCHING_CONNECTOR: 'datatypes.definitions.connectors.matchingConnector',
      },
      FABRICATION_RATES: {
        FABRICATION_REFERENCES_0_EXTERNAL_ID:
          'datatypes.definitions.fabricationRate.fabricationReferences0ExternalId',
      },
      INSTALLATION_RATES: {
        FABRICATION_REFERENCES_1_EXTERNAL_ID:
          'datatypes.definitions.installationRate.fabricationReferences1ExternalId',
      },
      DAMPERS: {
        NAME: 'datatypes.definitions.dampers.description',
        GAUGE: 'datatypes.definitions.dampers.gauge',
        INSET: 'datatypes.definitions.dampers.inset',
        ADJUST: 'datatypes.definitions.dampers.adjust',
        HOLE_ANGLE: 'datatypes.definitions.dampers.holeAngle',
        IS_DISPLAYED: 'datatypes.definitions.dampers.isDisplayed',
        IS_MACHINE_CUT: 'datatypes.definitions.dampers.isMachineCut',
        HANDLE_EXTENSION: 'datatypes.definitions.dampers.handleExtension',
        CONTROL_TYPE: 'datatypes.definitions.dampers.controlType',
        CATEGORY: 'datatypes.definitions.dampers.category',
      },
      STIFFENERS: {
        NAME: 'datatypes.definitions.stiffeners.description',
        GROUP: 'datatypes.definitions.stiffeners.group',
        END_TYPE: 'datatypes.definitions.stiffeners.endType',
        LONG_ADJUST: 'datatypes.definitions.stiffeners.longAdjust',
        CENTRAL_TYPE: 'datatypes.definitions.stiffeners.centralType',
        SHORT_ADJUST: 'datatypes.definitions.stiffeners.shortAdjust',
        OMIT_SHORT_SIDE_IF_LESS_THAN: 'datatypes.definitions.stiffeners.omitShortSideIfLessThan',
      },
      MATERIALS: {
        NAME: 'datatypes.definitions.materials.description',
        CATEGORY: 'datatypes.definitions.materials.category',
        ABBREVIATION: 'datatypes.definitions.materials.abbreviation',
        TYPE: 'datatypes.definitions.materials.type',
        MATERIAL_TYPE: 'datatypes.definitions.materials.type',
        MATERIAL_SPECIFICATIONS: 'datatypes.definitions.materials.materialSpecifications',
      },
      MATERIAL_SPECIFICATIONS: {
        NAME: 'datatypes.definitions.materialsSpec.description',
        CATEGORY: 'datatypes.definitions.materialsSpec.category',
        FORM: 'datatypes.definitions.materialsSpec.form',
        THICKNESS: 'datatypes.definitions.materialsSpec.thickness',
        WIRE_GAUGE: 'datatypes.definitions.materialsSpec.wireGauge',
        MATERIAL_SPECIFICATION_TYPE:
          'datatypes.definitions.materialsSpec.materialSpecificationType',
        BOARD_FLAT: 'datatypes.definitions.materialsSpec.boardFlat',
        ROLL_FLAT: 'datatypes.definitions.materialsSpec.rollFlat',
        SLEEVE_ROUND: 'datatypes.definitions.materialsSpec.sleeveRound',
      },
      PERMISSIONS: {
        FIRST_NAME: 'datatypes.definitions.permissions.firstName',
        LAST_NAME: 'datatypes.definitions.permissions.lastName',
        NAME: 'datatypes.definitions.permissions.name',
      },
      STIFFENER_SPECIFICATIONS: {
        STATUS: 'datatypes.definitions.stiffenersSpec.status',
      },
    },
  };

  static readonly ACCESS_RIGHTS = {
    ROLES: {
      OWNER: 'accessRights.roles.owner',
      WRITE: 'accessRights.roles.write',
      READ: 'accessRights.roles.read',
    },
    ADDITIONAL_ACCESS: {
      NONE: 'accessRights.additionalAccess.none',
      ALL: 'accessRights.additionalAccess.all',
    },
    FORM_HEADER: {
      ADD_USER: 'accessRights.header.addUser',
    },
    ERROR: {
      CONTENT_LIBRARY_NOT_FOUND: 'accessRights.error.contentLibraryNotFound',
      NO_USER: 'accessRights.error.noUser',
      INVALID_PARAMETERS: 'accessRights.error.invalidParameters',
      SERVER: 'accessRights.error.server',
    },
    DESCRIPTION: {
      NONE: 'accessRights.description.none',
      ALL: 'accessRights.description.all',
    },
    APPLY_LABEL: 'accessRights.applyLabel',
  };

  static readonly ENUMS = {
    // common enuns (please avoid duplication DRY)
    SHAPE_TYPE: {
      ROUND: 'enums.shapeType.round',
      RECTANGULAR: 'enums.shapeType.rectangular',
      OVAL: 'enums.shapeType.oval',
    },
    DOMAIN_TYPE: {
      ELECTRICAL_CONTAINMENT: 'enums.domainType.electricalContainment',
      HVAC: 'enums.domainType.hvac',
      NOT_SET: 'enums.domainType.notSet',
      PIPEWORK: 'enums.domainType.pipework',
    },
    CONNECTOR_BREAK_POINT_TABLE_SIDES_TYPE: {
      NONE: 'enums.connectorBreakPointTableSidesType.none',
      LEFT: 'enums.connectorBreakPointTableSidesType.left',
      TOP: 'enums.connectorBreakPointTableSidesType.top',
      LEFT_AND_TOP: 'enums.connectorBreakPointTableSidesType.leftAndTop',
      RIGHT: 'enums.connectorBreakPointTableSidesType.right',
      HORIZONTAL: 'enums.connectorBreakPointTableSidesType.horizontal',
      TOP_AND_RIGHT: 'enums.connectorBreakPointTableSidesType.topAndRight',
      LEFT_AND_TOP_AND_RIGHT: 'enums.connectorBreakPointTableSidesType.leftAndTopAndRight',
      BOTTOM: 'enums.connectorBreakPointTableSidesType.bottom',
      LEFT_AND_BOTTOM: 'enums.connectorBreakPointTableSidesType.leftAndBottom',
      VERTICAL: 'enums.connectorBreakPointTableSidesType.vertical',
      LEFT_AND_TOP_AND_BOTTOM: 'enums.connectorBreakPointTableSidesType.leftAndTopAndBottom',
      RIGHT_AND_BOTTOM: 'enums.connectorBreakPointTableSidesType.rightAndBottom',
      LEFT_AND_RIGHT_AND_BOTTOM: 'enums.connectorBreakPointTableSidesType.leftAndRightAndBottom',
      TOP_AND_RIGHT_AND_BOTTOM: 'enums.connectorBreakPointTableSidesType.topAndRightAndBottom',
      ALL: 'enums.connectorBreakPointTableSidesType.all',
    },

    CONNECTOR_DOMAIN_TYPE: {
      ELECTRICAL_CONTAINMENT: 'enums.connectorDomainType.electricalContainment',
      HVAC: 'enums.connectorDomainType.hvac',
      NOT_SET: 'enums.connectorDomainType.notSet',
      PIPEWORK: 'enums.connectorDomainType.pipework',
    },
    CONNECTOR_GENDER_TYPE: {
      MALE: 'enums.connectorGenderType.male',
      FEMALE: 'enums.connectorGenderType.female',
      NONE: 'enums.connectorGenderType.none',
    },
    CONNECTOR_SHAPE_TYPE: {
      ROUND: 'enums.connectorShapeType.round',
      RECTANGULAR: 'enums.connectorShapeType.rectangular',
      OVAL: 'enums.connectorShapeType.oval',
    },
    DAMPER_CONTROL_TYPE: {
      NONE: 'enums.damperControlType.none',
      PLAIN: 'enums.damperControlType.plain',
      MECHANICAL: 'enums.damperControlType.mechanical',
      CIRCLE: 'enums.damperControlType.circle',
      HANDLE: 'enums.damperControlType.handle',
      PUSH_PULL: 'enums.damperControlType.pushPull',
      SIMPLE: 'enums.damperControlType.simple',
    },
    MATERIAL_SPECIFICATION_TYPE: {
      INSULATION: 'enums.materialSpecificationType.insulation',
      ELECTRICAL_CONTAINMENT: 'enums.materialSpecificationType.electricalContainment',
      GENERIC: 'enums.materialSpecificationType.generic',
      PIPEWORK: 'enums.materialSpecificationType.pipework',
      SHEET_METAL: 'enums.materialSpecificationType.sheetMetal',
      DUCTBOARD: 'enums.materialSpecificationType.ductboard',
    },
    MATERIAL_SPECIFICATION_FORM: {
      COIL: 'enums.materialSpecificationForm.coil',
      SHEET: 'enums.materialSpecificationForm.sheet',
      GENERIC: 'enums.materialSpecificationForm.generic',
      TUBULAR: 'enums.materialSpecificationForm.tubular',
      BOARD: 'enums.materialSpecificationForm.board',
      ROLL: 'enums.materialSpecificationForm.roll',
      SLEEVE: 'enums.materialSpecificationForm.sleeve',
      NOT_SET: 'enums.materialSpecificationForm.notSet',
    },
    MATERIAL_TYPE: {
      MAIN: 'enums.materialType.main',
      INSULATION: 'enums.materialType.insulation',
      DUCTBOARD: 'enums.materialType.ductboard',
      FINISH: 'enums.materialType.finish',
    },
    OVAL_GEOMETRY_TYPE: {
      STANDARD: 'enums.ovalGeometryType.standard',
      BLANK: 'enums.ovalGeometryType.blank',
    },
    PIPEWORK_END_TYPE: {
      NOT_SET: 'enums.pipeworkEndType.notSet',
      BUTT_WELD: 'enums.pipeworkEndType.buttWeld',
      COMPRESSION: 'enums.pipeworkEndType.compression',
      FLANGED: 'enums.pipeworkEndType.flanged',
      LINER_CLAMPED: 'enums.pipeworkEndType.linerClamped',
      LINER_NUT: 'enums.pipeworkEndType.linerNut',
      REDUCING_LINER_NUT: 'enums.pipeworkEndType.reducingLinerNut',
      MALE_PART: 'enums.pipeworkEndType.malePart',
      PLAIN: 'enums.pipeworkEndType.plain',
      SCREWED: 'enums.pipeworkEndType.screwed',
      SOCKET_WELD: 'enums.pipeworkEndType.socketWeld',
    },
    RECTANGULAR_GEOMETRY_TYPE: {
      STANDARD: 'enums.rectangularGeometryType.standard',
      BLANK: 'enums.rectangularGeometryType.blank',
      STEP_DOWN: 'enums.rectangularGeometryType.stepDown',
      COUPLING_PLATE: 'enums.rectangularGeometryType.couplingPlate',
    },
    ROUND_GEOMETRY_TYPE: {
      STANDARD: 'enums.roundGeometryType.standard',
      BLANK: 'enums.roundGeometryType.blank',
      STEP_DOWN: 'enums.roundGeometryType.stepDown',
      HEX_END: 'enums.roundGeometryType.hexEnd',
      SQUARE: 'enums.roundGeometryType.square',
      PUSH_FIT: 'enums.roundGeometryType.pushFit',
    },
    ROUND_CONNECTOR_MEASUREMENT_MODE: {
      OUTSIDE_DIAMETER: 'enums.roundConnectorMeasurementMode.outsideDiameter',
      WALL_THICKNESS: 'enums.roundConnectorMeasurementMode.wallThickness',
    },
    STIFFENER_TYPE: {
      INTERNAL: 'enums.stiffenerType.internal',
      EXTERNAL: 'enums.stiffenerType.external',
      BOTH: 'enums.stiffenerType.both',
      NONE: 'enums.stiffenerType.none',
    },
    INSULATION_SPECIFICATION_TABLE_TYPE: {
      SINGLE_DIMENSION: 'enums.specificationTableType.singleDimension', // note ref to specificationTableType
      LONG_SIDE_SHORT_SIDE: 'enums.specificationTableType.longSideShortSide', // note ref to specificationTableType
    },
    SPECIFICATION_TABLE_TYPE: {
      SINGLE_DIMENSION: 'enums.specificationTableType.singleDimension',
      SINGLE_DIMENSION_PLUS_LENGTH: 'enums.specificationTableType.singleDimensionPlusLength',
      LONG_SIDE_SHORT_SIDE: 'enums.specificationTableType.longSideShortSide',
      LONG_SIDE_SHORT_SIDE_PLUS_LENGTH: 'enums.specificationTableType.longSideShortSidePlusLength',
    },
    SPECIFICATION_APPLICATION_TYPE: {
      FITTINGS_ONLY: 'enums.specificationApplicationType.fittingsOnly',
      STRAIGHTS_ONLY: 'enums.specificationApplicationType.straightsOnly',
      ALL_TYPES: 'enums.specificationApplicationType.allTypes',
    },
    MODEL_BY: {
      PART_SIZE: 'enums.modelBy.partSize',
      INSULATION: 'enums.modelBy.insulation',
    },
    INSULATION_TYPE: {
      INSIDE: 'enums.insulationType.inside',
      OUTSIDE: 'enums.insulationType.outside',
    },
    HANGER_ENGAGEMENT: {
      AROUND_PART: 'enums.hangerEngagement.aroundPart',
      AROUND_INSULATION: 'enums.hangerEngagement.aroundInsulation',
    },
  };

  static readonly GRAPH = {
    NO_REFERENCES: 'graph.noReferences',
    UNASSIGNED: 'graph.unassigned',
    PAGINATION: {
      NEXT_PAGE: 'graph.pagination.nextPage',
      PREVIOUS_PAGE: 'graph.pagination.previousPage',
      FROM: 'graph.pagination.from',
    },
    TOP_CONTROLS: {
      SEARCH: 'graph.topControls.search',
      SORT: 'graph.topControls.sort',
      FILTER: 'graph.topControls.filter',
    },
    NODE_OPTIONS: {
      EDIT: 'graph.nodeOptions.edit',
      FOCUS: 'graph.nodeOptions.focus',
      VIEW: 'graph.nodeOptions.view',
      UPGRADE: 'graph.nodeOptions.upgrade',
    },
  };

  static readonly DATA_GRID = {
    ERRORS: {
      CALCULATION_ERROR: {
        MESSAGE: 'dataGrid.errors.calculationError.message',
        DESCRIPTION: 'dataGrid.errors.calculationError.description',
      },
      CIRCULAR_DEPENDENCY: {
        MESSAGE: 'dataGrid.errors.circularDependency.message',
        DESCRIPTION: 'dataGrid.errors.circularDependency.description',
      },
      INVALID_CALCULATION: {
        MESSAGE: 'dataGrid.errors.invalidCalculation.message',
        DESCRIPTION: 'dataGrid.errors.invalidCalculation.description',
      },
      MAX_LENGTH: {
        MESSAGE: 'dataGrid.errors.maxLength.message',
        DESCRIPTION: 'dataGrid.errors.maxLength.description',
      },
      MAX_VALUE: {
        MESSAGE: 'dataGrid.errors.maxValue.message',
        DESCRIPTION: 'dataGrid.errors.maxValue.description',
        INCLUSIVE_DESCRIPTION: 'dataGrid.errors.maxValue.inclusiveDescription',
      },
      MIN_VALUE: {
        MESSAGE: 'dataGrid.errors.minValue.message',
        DESCRIPTION: 'dataGrid.errors.minValue.description',
      },
      REQUIRED: {
        MESSAGE: 'dataGrid.errors.required.message',
        DESCRIPTION: 'dataGrid.errors.required.description',
      },
      SAVE_INVALID_CALCULATION: {
        MESSAGE: 'dataGrid.errors.saveInvalidCalculation.message',
      },
      FORMULAS_NOT_ALLOWED: {
        MESSAGE: 'dataGrid.errors.formulasNotAllowed.message',
      },
      UNKNOWN_IDENTIFIER: {
        MESSAGE: 'dataGrid.errors.unknownIdentifier.message',
      },
    },
    TOOLTIPS: {
      APPLY_TO_ALL: {
        TITLE: 'dataGrid.tooltips.applyToAll.title',
        DESCRIPTION: 'dataGrid.tooltips.applyToAll.description',
        DISABLED_DESCRIPTION: 'dataGrid.tooltips.applyToAll.disabledDescription',
      },
      CIRCULAR_DEPENDENCY_OPTION: {
        TITLE: 'dataGrid.tooltips.circularDependencyOption.title',
        DESCRIPTION: 'dataGrid.tooltips.circularDependencyOption.description',
      },
      DISABLED_FIELD_IN_CALCULATION: {
        TITLE: 'dataGrid.tooltips.disabledFieldInCalculation.title',
      },
    },
    CONFIRMATION_MODAL: {
      FORMULA_PROPAGATION: {
        TITLE: 'dataGrid.confirmationModal.formulaPropagation.title',
        MESSAGE: 'dataGrid.confirmationModal.formulaPropagation.message',
      },
      FORMULA_TRANSLATION: {
        TITLE: 'dataGrid.confirmationModal.formulaTranslation.title',
        MESSAGE: 'dataGrid.confirmationModal.formulaTranslation.message',
      },
    },
    AUTOCOMPLETION: {
      FIELDS: 'dataGrid.autocompletion.fields',
      FUNCTIONS: 'dataGrid.autocompletion.functions',
      PRESETS: 'dataGrid.autocompletion.presets',
    },
  };

  static readonly DIMENSIONS_TABLE = {
    NO_DATA: {
      TITLE: 'dimensionsTable.noData.title',
      DESCRIPTION: 'dimensionsTable.noData.description',
    },
    CLEAR_FILTER: 'dimensionsTable.clearFilter',
    NO_RESULTS: {
      TITLE: 'dimensionsTable.noResults.title',
      DESCRIPTION: 'dimensionsTable.noResults.description',
    },
    HEADER_MENU: {
      FIELD_SETTINGS_TITLE: 'dimensionsTable.headerMenu.fieldSettingsTitle',
      EDITABLE_AFTER_PLACEMENT: {
        TITLE: 'dimensionsTable.headerMenu.editableAfterPlacement.title',
        DESCRIPTION: 'dimensionsTable.headerMenu.editableAfterPlacement.description',
        DISABLED_DESCRIPTION:
          'dimensionsTable.headerMenu.editableAfterPlacement.disabledDescription',
      },
    },
    TOOLTIP: {
      INACCURATE_VALUES: {
        MESSAGE: 'dimensionsTable.tooltip.inaccurateValues.message',
        DESCRIPTION: 'dimensionsTable.tooltip.inaccurateValues.description',
      },
      INVALID_DIMENSION: {
        MESSAGE: 'dimensionsTable.tooltip.invalidDimension.message',
        DESCRIPTION: 'dimensionsTable.tooltip.invalidDimension.description',
      },
      DISABLE_DELETE: {
        DESCRIPTION: 'dimensionsTable.tooltip.disableDelete.description',
      },
      UNIQUE_PRODUCT_ENTRY: {
        MESSAGE: 'dimensionsTable.tooltip.uniqueProductEntry.message',
      },
    },
  };

  static readonly DYNAMIC_FORM = {
    BUTTONS: {
      APPLY: 'dynamicForm.buttons.apply',
      REVERT: 'dynamicForm.buttons.revert',
      CANCEL: 'dynamicForm.buttons.cancel',
      LOAD_MORE: 'dynamicForm.buttons.loadMore',
    },
    TABS: {
      BASIC: 'dynamicForm.tabs.basic',
      MANUFACTURING: 'dynamicForm.tabs.manufacturing',
      ESTIMATING: 'dynamicForm.tabs.estimating',
      GEOMETRY: 'dynamicForm.tabs.geometry',
      DETAILS: 'dynamicForm.tabs.details',
      PALETTES: 'dynamicForm.tabs.palettes',
      PARTS: 'dynamicForm.tabs.parts',
      SIZE_ENTRIES: 'dynamicForm.tabs.sizeEntries',
      SIZE_RESTRICTIONS: 'dynamicForm.tabs.sizeRestrictions',
      SIZES: 'dynamicForm.tabs.sizes',
      TABLES: 'dynamicForm.tabs.tables',
    },
    GROUPS: {
      BODY: 'dynamicForm.groups.body',
      PRODUCT_ENTRIES: 'dynamicForm.groups.productEntries',
    },
    TITLE_PLACEHOLDER: 'dynamicForm.titlePlaceholder',
  };

  static readonly BADGE_TOOLTIP = {
    ERRORS: {
      DEFAULT: 'badgeTooltip.errors.default',
      UNKNOWN: 'badgeTooltip.errors.unknown',
      ALREADY_EXISTS: 'badgeTooltip.errors.alreadyExists',
    },
    UPGRADES: {
      REQUIRES_UPGRADE: 'badgeTooltip.upgrades.requiresUpgrade',
      IN_PROGRESS: 'badgeTooltip.upgrades.inProgress',
    },
    INVALID_DATA: {
      INVALID_DATA: 'badgeTooltip.invalidData.invalidData',
    },
  };

  static readonly HELP_CONTENT = {
    DEFAULT: {
      TITLE: 'helpContent.default.title',
      CONTENT: 'helpContent.default.content',
    },
    CONNECTOR: {
      TITLE: 'helpContent.connector.title',
      CONTENT: 'helpContent.connector.content',
    },
    DAMPER: {
      TITLE: 'helpContent.damper.title',
      CONTENT: 'helpContent.damper.content',
    },
    STIFFENER: {
      TITLE: 'helpContent.stiffener.title',
      CONTENT: 'helpContent.stiffener.content',
    },
    MATERIAL: {
      TITLE: 'helpContent.material.title',
      CONTENT: 'helpContent.material.content',
    },
    SERVICE: {
      TITLE: 'helpContent.service.title',
      CONTENT: 'helpContent.service.content',
    },
    MATERIAL_SPECIFICATION: {
      TITLE: 'helpContent.materialSpecification.title',
      CONTENT: 'helpContent.materialSpecification.content',
    },
    SERVICE_TEMPLATE: {
      TITLE: 'helpContent.serviceTemplate.title',
      CONTENT: 'helpContent.serviceTemplate.content',
    },
    INSULATION_SPECIFICATION: {
      TITLE: 'helpContent.insulationSpecification.title',
      CONTENT: 'helpContent.insulationSpecification.content',
    },
    SPECIFICATION: {
      TITLE: 'helpContent.partSpecification.title',
      CONTENT: 'helpContent.partSpecification.content',
    },
    PARTS: {
      TITLE: 'helpContent.parts.title',
      CONTENT: 'helpContent.parts.content',
    },
    INVALID_DATA: {
      TITLE: 'helpContent.invalidData.title',
      CONTENT: 'helpContent.invalidData.content',
    },
    PERMISSION: {
      TITLE: 'helpContent.permission.title',
      CONTENT: 'helpContent.permission.content',
    },
  };

  static readonly ERROR_HANDLING = {
    GENERIC: {
      EDITABLE: 'errorHandling.generic.editable',
      INVALID_ATTRIBUTE: 'errorHandling.generic.invalidAttribute',
      INVALID_REFERENCE: 'errorHandling.generic.invalidReference',
      MULTIPLE_ERRORS: 'errorHandling.generic.multipleErrors',
      UNKNOWN: 'errorHandling.generic.unknown',
      YES: 'dynamicTable.yes',
      NO: 'dynamicTable.no',
      NOT_YET: 'errorHandling.generic.notYet',
      PAYLOAD_TOO_BIG: 'errorHandling.generic.payloadTooBig',
      UNEXPECTED_ERROR: 'errorHandling.generic.unexpectedError',
      TRY_AGAIN: 'errorHandling.generic.tryAgain',
      REPORT_ISSUE: 'errorHandling.generic.reportIssue',
      RETRY: 'errorHandling.generic.retry',
      UNABLE_TO_GET_MORE_RESULTS: 'errorHandling.generic.unableToGetMoreResults',
      TEMPORARY_DATA_ISSUE: 'errorHandling.generic.temporaryDataIssue',
    },
    PARTS: {
      CORRUPT_PART: 'errorHandling.parts.corruptPart',
      TOO_MANY_ENTRIES: 'errorHandling.parts.tooManyEntries',
      NO_ENTRIES: 'errorHandling.parts.noEntries',
      SEARCH: 'errorHandling.parts.search',
      NO_RESULTS: 'errorHandling.parts.noResults',
      CIRCULAR_CALCULATION_REFERENCES: 'errorHandling.parts.circularCalculationReferences',
      INVALID_DIMS: 'errorHandling.parts.invalidDims',
      INVALID_CALCULATION_REFERENCES: 'errorHandling.parts.invalidCalculationReferences',
      CIRCULAR_DEPENDENCY_REFERENCES: 'errorHandling.parts.circularDependencyReferences',
      UNSUPPORTED_PRESET_SETUP: 'errorHandling.parts.unsupportedPresetSetup',
    },
    SERVICE_TEMPLATES: {
      INVALID_SIZE_RESTRICTION: 'errorHandling.partTemplates.invalidSizeRestriction',
    },
    SERVICES: {
      NO_PART_TEMPATE: 'errorHandling.services.noPartTemplate',
    },
    MATERIALS: {
      UNASSIGNED_MATERIAL_SPECS_WARNING: 'errorHandling.materials.unassignedMaterialSpecsWarning',
    },
    MATERIAL_SPECS: {
      UNSUPPORTED_TYPE: 'errorHandling.materialSpecifications.unsupportedType',
    },
    INSULATION_SPECS: {
      INVALID_MATERIAL: 'errorHandling.insulationSpecifications.invalidMaterial',
      INVALID_MATERIAL_SPECIFICATION:
        'errorHandling.insulationSpecifications.invalidMaterialSpecification',
    },
  };

  static readonly CONFIRMATION_MODAL = {
    TITLE: {
      SINGLE_DELETE_USER: 'confirmationModal.title.singleDeleteUser',
      MULTIPLE_DELETE_USER: 'confirmationModal.title.multipleDeleteUser',
    },
    MESSAGE: {
      SINGLE_DELETE_USER_MESSAGE: 'confirmationModal.message.singleDeleteUserMessage',
      MULTIPLE_DELETE_USER_MESSAGE: 'confirmationModal.message.multipleDeleteUserMessage',
    },
    CANCEL: 'confirmationModal.cancel',
    DELETE: 'confirmationModal.delete',
  };

  static readonly PART_MORE_OPTION = {
    INPUT_TYPE: 'partMoreOption.inputType',
    INPUT_TYPES: {
      CALCULATION: 'partMoreOption.inputTypes.calculation',
      CALCULATION_DESCRIPTION: 'partMoreOption.inputTypes.calculationDescription',
      INPUT: 'partMoreOption.inputTypes.input',
      INPUT_DESCRIPTION: 'partMoreOption.inputTypes.inputDescription',
      LIST: 'partMoreOption.inputTypes.list',
      LIST_DESCRIPTION: 'partMoreOption.inputTypes.listDescription',
    },
    TYPE: 'partMoreOption.type',
    TYPE_DESCRIPTION: 'partMoreOption.typeDescription',
    INSTANCE: 'partMoreOption.instance',
    INSTANCE_DESCRIPTION: 'partMoreOption.instanceDescription',
    SINGLE_ENTRY: 'partMoreOption.singleEntry',
  };

  static readonly PART_SEARCH = {
    PART_TABLE: {
      NAME: 'partTable.name',
      MATERIAL: 'partTable.material',
      NONE: 'partTable.none',
    },
    MODAL: {
      HEADER: 'modal.header',
      CANCEL: 'modal.cancel',
      DELETE: 'modal.delete',
      CONTENT: 'modal.content',
    },
  };

  static readonly PART_THUMBNAIL_MENU = {
    GENERATE_3D_MODEL: 'partThumbnailMenu.generate3DModel',
  };

  static readonly THUMBNAIL_EDITOR = {
    UPLOAD_FILE: 'thumbnailEditor.uploadFile',
    SUPPORTED_FORMATS: 'thumbnailEditor.supportedFormats',
    MAX_SIZE: 'thumbnailEditor.maxSize',
    MB: 'thumbnailEditor.mb',
  };
}
