import {
  CIRCLE_RADIUS,
  DEFAULT_PAGE_SIZE,
  FIXED_NORESULT_NODE_DATA,
  NODE_SPACE_X,
  NodeTooltipOptions,
  PageControlPosition,
  PaginationAction,
  RectSizes,
  SortOptions,
  TreePosition,
} from './d3-graph.constants';
import { isEmpty, kebabCase, startCase } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { GraphNode, HierarchyGraphNode, PaginationOptions } from './d3-graph.types';
import { FabricationConnectorReference } from '@models/forge-content/references';
import { TranslateService } from '@ngx-translate/core';
import { LocalisationConstants as LC } from '@constants/localisation-constants';
import { getI18nConstantRef } from '@utils/translate-utils';
import { DynamicDataService } from '@services/dynamic-data.service';
import { higSvgIcons } from '@constants/icon-constants';
import { DataElementTypeUtils } from '@utils/data-element-type-utils';

/**
 * generate d3 hierarchy node hash like id based on data and parent
 *
 * @param {HierarchyGraphNode} node
 * @returns
 */
export const generateNodeId = (node: HierarchyGraphNode) => {
  let significantInfo = [
    node.data.dataType,
    node.data.id,
    node.data.internalRelationshipDataType,
    node.data.internalRelationshipId,
    node.depth,
    (node?.data?.referenceMetaData as FabricationConnectorReference)?.index,
    ...Object.values(node.data.info ?? {}),
  ];

  significantInfo = significantInfo.filter(Boolean);
  const hashCode = Array.from(significantInfo.join(',')).reduce(
    (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0,
    0
  );
  return Math.abs(hashCode).toString();
};

export const getNodeHeight = (node: HierarchyGraphNode) => {
  return RectSizes.HEIGHT;
};

export const getControlOperationTranslation = (
  d,
  ref: PageControlPosition,
  treePosition: TreePosition
) => {
  const positionFactor = treePosition === TreePosition.UPSTREAM ? -1 : 1;
  const differential = ref === PageControlPosition.BOTTOM ? -40 : 84;
  const dx = d.y * positionFactor + (RectSizes.WIDTH + 10) * positionFactor * (positionFactor * -1);
  const dy = d.x + differential * positionFactor * (positionFactor * -1);

  return `translate(${dx},${dy})`;
};

export const getNextOrPrev = (totalPages: number, page: number, action: PaginationAction) => {
  page = action === PaginationAction.NEXT ? page + 1 : page - 1;
  page = page <= 0 ? 1 : page > totalPages ? totalPages : page;
  return page;
};

export const getPageString = (page: number, totalPages: number, translate: TranslateService) => {
  return `${page} ${translate.instant(LC.GRAPH.PAGINATION.FROM)} ${totalPages}`;
};

export const defaultPaginationOptions: PaginationOptions = {
  sort: SortOptions.ASC,
  page: 1,
  limit: DEFAULT_PAGE_SIZE,
  search: '',
};

export const generateFixedNoResultNodeData = (search: string, parent, dataType: string) => ({
  ...FIXED_NORESULT_NODE_DATA,
  data: {
    id: `no-results-${uuidv4()}`,
    hasNoResult: true,
    value: search,
    dataType,
  },
  depth: parent.depth + 1,
  y: parent.y + (parent.y < 0 ? -290 : +290),
  y0: parent.y0 + (parent.y0 < 0 ? -290 : +290),
  x: parent.x,
  x0: parent.x0,
  parent,
});

export const generateElbowPath = (d) => {
  const spaceFactor = d.target.y < 0 ? 1 : -1;
  return (
    'M' +
    d.source.y +
    ',' +
    d.source.x +
    'H' +
    (d.target.y + (NODE_SPACE_X / 2) * spaceFactor) +
    'V' +
    d.target.x +
    'H' +
    d.target.y
  );
};

export const getArrowIconFromData = (d, treePosition: TreePosition) => {
  const leftCaretIcon = 'assets/hig/16/caret-graph-left.svg';
  const rightCaretIcon = 'assets/hig/16/caret-graph-right.svg';

  if (treePosition === TreePosition.DOWNSTREAM) {
    return d._children || d.data.isExpandable ? (d.children ? leftCaretIcon : rightCaretIcon) : '';
  } else {
    return (!isEmpty(d._children) && !d._children[0].data.hasNoReference) || d.data.isExpandable
      ? d.children
        ? rightCaretIcon
        : leftCaretIcon
      : '';
  }
};

export const getClassArrowIconFromData = (d, treePosition: TreePosition) => {
  const leftCaretIconClass = 'left-caret-icon';
  const rightCaretIconClass = 'right-caret-icon';

  if (treePosition === TreePosition.DOWNSTREAM) {
    return d._children || d.data.isExpandable
      ? d.children
        ? leftCaretIconClass
        : rightCaretIconClass
      : '';
  } else {
    return (!isEmpty(d._children) && !d._children[0].data.hasNoReference) || d.data.isExpandable
      ? d.children
        ? rightCaretIconClass
        : leftCaretIconClass
      : '';
  }
};

export const generateHtmlTopControls = (translate: TranslateService) => {
  return `
    <ul class="group-node-filters">
      <li class="search-nodes" data-test-element="pagination-search-bar-container">
        <div></div>
      </li>
      <li class="filter-nodes" style="display:none;">
        <div class="divider"></div>
        <img tabindex="0" src="assets/hig/16/filter.svg" class="filter-icon" alt="${translate.instant(
          LC.GRAPH.TOP_CONTROLS.FILTER
        )}">
      </li>
      <li class="sort-nodes" style="display:none;">
        <div class="divider"></div>
        <img tabindex="0" src="assets/hig/16/sort.svg" class="sort-icon" alt="${translate.instant(
          LC.GRAPH.TOP_CONTROLS.SORT
        )}">
      </li>
    </ul>
  `;
};

export const generateHtmlBottomControls = ({ page, totalPages }, translate: TranslateService) => {
  return `
      <ul class="group-node-pagination">
        <li class="prev-nodes-page ${
          page === 1 ? 'disabled' : ''
        }" data-test-element="pagination-prev">
          <button class="page-control" type="button" aria-label="${translate.instant(
            LC.GRAPH.PAGINATION.PREVIOUS_PAGE
          )}">
            <span>
              <img src="assets/hig/16/caret-left.svg" class="previous-page-icon" alt="${translate.instant(
                LC.GRAPH.PAGINATION.PREVIOUS_PAGE
              )}">
            </span>
          </button>
        </li>
        <li class="pagination-control" data-test-element="pagination-counter">
          <span>${getPageString(page, totalPages, translate)}</span>
        </li>
        <li class="next-nodes-page ${
          page === totalPages ? 'disabled' : ''
        }" data-test-element="pagination-next">
          <button class="page-control" type="button" aria-label="${translate.instant(
            LC.GRAPH.PAGINATION.NEXT_PAGE
          )}" ${page === totalPages ? 'disabled' : ''}>
            <span>
              <img src="assets/hig/16/caret-right.svg" class="next-page-icon" alt="${translate.instant(
                LC.GRAPH.PAGINATION.NEXT_PAGE
              )}">
            </span>
          </button>
        </li>
      </ul>
    `;
};

export const generateHtmlBox = (data: GraphNode, isFocused: boolean) => {
  const infoMap = data.info;
  const imageSrc = data.image;
  const infoKeys = infoMap && Object.keys(infoMap).filter((i) => i !== 'name');

  const showAdditionalData =
    (data.isEditable && (!data.isInvalidData || isFocused)) ||
    (data.requiresUpgrade && !data.isUpgrading) ||
    data.isUpgrading ||
    data.isInvalidData;

  return `
    <div class="d3-graph-node-inner-html wordwrap"
      data-test-element="node-inner-html"
      style="width: ${RectSizes.WIDTH - CIRCLE_RADIUS * 2}px; height: ${
    RectSizes.HEIGHT - (CIRCLE_RADIUS - 2)
  }px">
       ${
         showAdditionalData || infoMap.name
           ? `
 <div class="node-top-row">
      ${
        infoMap.name
          ? `
       <div class="node-name" data-tooltip-text="${infoMap.name}" data-test-element="node-name">${infoMap.name}</div>`
          : ''
      }
            <div class="node-options-container">
            ${
              data.isEditable && (!data.isInvalidData || isFocused)
                ? '<button class="node-options-btn" data-test-element="node-options-btn"><i class="hig-icon-more-16"></i></button>'
                : ''
            }
            ${
              data.requiresUpgrade && !data.isUpgrading
                ? '<button class="node-icons" data-test-element="node-upgrade-badge"><i class="hig-sync-badge"></i></button>'
                : ''
            }
            ${
              data.isUpgrading
                ? '<button class="node-icons" data-test-element="node-progress-ring"><i class="hig-progress-ring"></i></button>'
                : ''
            }
            ${
              data.isInvalidData
                ? '<button class="node-icons" data-test-element="node-invalid-badge"><i class="hig-alert-badge"></i></button>'
                : ''
            }
          </div>
        </div>`
           : ''
       }

        <div class="node-info" data-test-element="node-info">
          <div class="node-params">
            ${
              imageSrc
                ? `<div class='graph-node-image' data-cy='image-${kebabCase(infoMap.name)}'>
                  <img/>
                </div>`
                : ''
            }
          <div class='info-text' data-test-element="node-info-params">
            ${
              infoKeys &&
              infoKeys
                .map(
                  (k) =>
                    `<p class="info-text-item">${startCase(k)}: <span data-tooltip-text="${
                      infoMap[k]
                    }" class="info-text-value">${infoMap[k]}</span></p>`
                )
                .join('')
            }
          </div>
        </div>
      </div>
    </div>`;
};

export const generateHTMLClusterLabel = (
  dataType: any,
  internalDataType: any,
  translate: TranslateService,
  dataService: DynamicDataService
) => {
  const dataTypeReference = internalDataType
    ? translate.instant(LC.DATATYPES.TYPES[getI18nConstantRef(internalDataType)])
    : DataElementTypeUtils.getStartCase(translate, dataType, false);

  let icon = dataService
    .getDynamicDataSetupForType(dataType)
    ?.getDynamicGraphOptions()?.clusterIcon;
  if (!icon) {
    icon = 'placeholder';
  }

  const iconPath = higSvgIcons.find((x) => x.name === icon).path;
  return `
    <div class="d3-graph-cluster-inner-html wordwrap"
      data-test-element="cluster-inner-html"
      style="width: 100%; height: 100%; padding: 6px;"
    >
      <img src="${iconPath}" />
      <b>${dataTypeReference}</b>
    </div>`;
};

export const generateHtmlNoResultsNode = (translate: TranslateService) => {
  return `
    <div class="no-results-container">
      <p>
        <i>
          <img src='/assets/hig/empty-state/empty-state-no-data-light-gray.svg' />
        </i>
        ${translate.instant(LC.GRAPH.NO_REFERENCES)}
      </p>
    </div>`;
};

export const generateHtmlUnassignedNode = (translate: TranslateService) => {
  return `
    <div class="no-results-container">
      <p>
        <i>
          <img src='/assets/hig/empty-state/empty-state-first-use-light-gray.svg' />
        </i>
        ${translate.instant(LC.GRAPH.UNASSIGNED)}
      </p>
    </div>`;
};

export const generateTooltipHtml = (content?: string) => {
  return `
  <div class="node-options-tooltip">
    <div class="tooltip-arrow">
      <svg height="12" viewBox="0 0 12 12" width="12">
        <polygon fill="#535353" points="0,6 6,0 12,6"></polygon>
        <polygon fill="#535353" points="2.5,6 6,2.5 9.5,6"></polygon>
      </svg>
    </div>
    <div class="tooltip-content">
      <div class="tooltip-container">
        <div>
          ${content}
        </div>
      </div>
    </div>
  </div>`;
};

export const generateTooltipHelpText = (text: string): string => {
  const span = `
    <div class="node-info-tooltip-content">
      <span class="text-content">${text}</span>
    </div>`;

  return generateTooltipHtml(span);
};

export const generateTooltipOptionsHtml = (
  { data, currentNodeData }: { data: GraphNode; currentNodeData: GraphNode },
  translate: TranslateService,
  currentUserIsReadOnly: boolean
): string => {
  const options = Object.values(NodeTooltipOptions);
  const relabel = (opt: string): string => {
    if (opt === NodeTooltipOptions.EDIT) {
      if (currentUserIsReadOnly) {
        return translate.instant(LC.GRAPH.NODE_OPTIONS.VIEW);
      }
    }

    return translate.instant(LC.GRAPH.NODE_OPTIONS[opt.toUpperCase()]);
  };

  return generateTooltipHtml(
    `<ul>${options
      .map((opt) => {
        if (
          (opt === NodeTooltipOptions.EDIT &&
            data.isEditable &&
            !data.isInvalidData &&
            !data.requiresUpgrade &&
            !data.isUpgrading) ||
          (opt === NodeTooltipOptions.FOCUS && data.dbid !== currentNodeData.dbid)
        ) {
          return `
            <li data-option="${opt}"  >
              ${relabel(opt)}
            </li>`;
        } else if (opt === NodeTooltipOptions.UPGRADE && data.requiresUpgrade) {
          return `
              <li data-option="${opt}" ${
            currentUserIsReadOnly || data.isUpgrading || data.isInvalidData
              ? 'style="pointer-events:none; opacity:0.6;"'
              : ''
          } >
                ${relabel(opt)}
              </li>
            `;
        } else {
          return undefined;
        }
      })
      .filter(Boolean)
      .join('')}</ul>`
  );
};
